<template>
  <div class="container-fluid shop-container">
    <b-card class="shop-card">
      <b-row
        no-gutters
        class="row-style"
      >
        <b-col
          :cols="getTopNavMenuCol()"
          class="mt-2 ml-3"
        >
          <d-body-nav-bar
            :tabs="$store.getters['menu/getTabs'].shopIndex2"
          />
        </b-col>
        <b-col
          v-if="$store.getters['layout/getInnerWidth'] > 1332 && from !== 'customer'"
          cols="4"
          class="mt-3 mr-2 text-right"
        >
          <d-button
            icon-size="15"
            text="views.shop.new-product"
            icon="fa fa-plus-circle mr-1"
            class="ml-2 d-btn btn d-btn-primary font-text-title"
            @on:button-click="createProduct()"
          />
          <d-button
            icon-size="15"
            icon="fa fa-clock-o mr-1"
            text="views.shop.show-all-sales"
            class="ml-2 d-btn btn d-btn-danger font-text-title"
            @on:button-click="showSales()"
          />
        </b-col>
        <b-col
          v-if="$store.getters['layout/getInnerWidth'] > 1332"
          class="mt-3 mr-3"
        >
          <div class="has-search">
            <span class="fa fa-search form-control-feedback"></span>
            <input
              v-model="filter"
              :placeholder="$t('components.doinsport.table.search')"
              type="text"
              class="form-control"
            >
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2" v-if="$store.getters['layout/getInnerWidth'] < 1332">
        <b-col
          v-if="from !== 'customer'"
          :cols="getBtnsCols()" class="pt-0 ml-0">
          <d-button
            icon-size="15"
            icon="fa fa-plus-circle mr-1"
            text="views.shop.new-product"
            class="ml-2 d-btn btn d-btn-primary font-text-title"
            @on:button-click="createProduct()"
          />
        </b-col>
        <b-col
          v-if="from !== 'customer'"
          :cols="getBtnsCols()"
        >
          <d-button
            icon-size="15"
            icon="fa fa-clock-o mr-1"
            text="views.shop.show-all-sales"
            class="ml-2 d-btn btn d-btn-danger font-text-title"
            @on:button-click="showSales()"
          />
        </b-col>
        <b-col :class="getColsClasses('search')" :cols="getBtnsCols('search')">
          <div class="btn-full-width has-search">
            <span class="fa fa-search form-control-feedback"></span>
            <input
              type="text"
              class="form-control"
              v-model="filter"
              :placeholder="$t('components.doinsport.table.search')"
            >
          </div>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center align-items-center spinner-busy" v-if="isBusy">
        <b-spinner
          variant="blue-light-spinner"
          type="grow"></b-spinner>
      </div>
      <template v-else>
        <template
          v-if="products.length > 0"
        >
          <div
            class="flex-cards"
            :style="{ gridTemplateColumns: cols }"
          >
            <template v-for="(product, index) of products.slice()">
              <product-card
                :from="from"
                :customer="customer"
                :key="'product' + product.id"
                :product="product"
                @on:reload-products="reloadProducts(index)"
              />
            </template>
          </div>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            pills
            align="center"
            class="mb-4"

          ></b-pagination>
        </template>
      </template>
      <div
        v-if="products.length === 0 && !isBusy && from !== 'customer'"
        class="d-flex flex-column align-items-center justify-content-center no-products-container"
      >
        <p class="no-products">{{ $t('views.shop.no-products-message') }}</p>
        <d-button
          text="views.shop.new-product"
          class="ml-2 d-btn btn d-btn-primary-outline font-text-title"
          iconSize="15"
          icon="fa fa-plus-circle mr-1"
          @on:button-click="createProduct()"
        />
      </div>
    </b-card>
  </div>
</template>
<script>
import {getAllProducts} from "@/api/doinsport/services/products/products.api.js";

export default {
  props: {
    customer: {
      type: Object,
      default: null
    },
    from: {
      type: String,
      default: ''
    }
  },
  components: {
    ProductCard: () => import('./card/ProductCard'),
  },
  data: () => ({
    filter: '',
    timer: null,
    isBusy: true,
    products: [],
    perPage: 10,
    rows: 1,
    currentPage: 1,

  }),
  watch: {
    currentPage: function () {
      this.loadAllProducts();
    },
    filter: function () {
      this.manualFilter();
    }
  },
  computed: {
    cols() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326 && width >= 1140) {
        return 'repeat(4, 1fr)';
      } else if (width < 1140 && width >= 866) {
        return 'repeat(3, 1fr)';
      } else if (width < 866) {
        return 'repeat(1, 1fr)';
      } else {
        if (this.from === 'customer') {
          return 'repeat(4, 1fr)';
        }
        return 'repeat(5, 1fr)';
      }
    },
  },
  methods: {
    getColsClasses(btn) {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (btn === 'search') {
        if (width < 1326 && width >= 924) {
          return '';
        } else {
          return 'mt-2';
        }
      }

      if (width < 1326 && width >= 924) {
        return '';
      } else {
        return 'mt-2';
      }
    },
    getTopNavMenuCol() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326) {
        return '12';
      } else {
        return '6';
      }
    },
    getBtnsCols(btn) {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (btn === 'search') {
        if (width < 1326 && width >= 924) {
          return '4';
        } else {
          return '12';
        }
      }
      if (width < 1326 && width >= 924) {
        return '4';
      } else {
        return '6';
      }
    },
    manualFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.loadAllProducts();
      }, 400);
    },
    loadAllProducts() {
      this.isBusy = true;

      let props = '';
      if (this.from === 'customer') {
        props = '&type=physical'
      }

      getAllProducts(this.perPage, this.currentPage, this.filter, props)
        .then((response) => {
          this.rows = response.data['hydra:totalItems'];
          this.products = response.data['hydra:member'];
        })
        .finally(() => {
          this.isBusy = false;
        })
      ;
    },
    reloadProducts(index) {
      this.products.splice(index, 1);
    },
    createProduct() {
      this.$router.push({name: 'new_product'});
    },
    showSales() {
      this.$router.push({name: 'sales'});
    },
  },
  created() {
    this.loadAllProducts();
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/shop/_product-stock.scss";
@import "@lazy/_b-card.scss";
</style>
